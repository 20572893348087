import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import "./studentOutcome.css"

// import {StripeProvider} from 'react-stripe-elements';
// import {Stripe} from 'stripe.js'
// import Checkout from '../../components/checkout';

import Yen from "../../pics/students/yen.jpg"

const YenStory = () => (
	<div className="white grid-1-3 studentoutcome">
		<Helmet
			title="Student's story: Yen Hoang, developer at Mind The Bridge"
			meta={[
				{
					name: "description",
					content: "I think I've learned more than I expected.",
				},
				{
					name: "keywords",
					content: "Students story, Barcelona Code School",
				},
				{
					name: "viewport",
					content: "width=device-width, initial-scale=1",
				},
				{
					property: "og:title",
					content:
						"Yen Hoang, developer at Mind The Bridge",
				},
				{
					property: "og:description",
					content: "I think I've learned more than I expected.",
				},
				{
					property: "og:image",
					content:
						"https://barcelonacodeschool.com/blog-pics/2021/aboutme.c2f4f8be.jpg",
				},
				{
					property: "og:url",
					content:
						"https://barcelonacodeschool.com/students-outcomes/yen",
				},
				{ name: "twitter:card", content: "summary_large_image" },
			]}
		/>
		<div>
			<h1>Student's story: Yen Hoang</h1>
			<h2>
				Graduated from the{" "}
				<a href="/barcelona-code-school/javascript-full-stack-online-bootcamp/">
					JavaScript Full-Stack Online Bootcamp
				</a>
			</h2>
			<h2>Job landed: developer at Mind The Bridge</h2>
		</div>
		<div
			style={{
				marginTop: "2em",
			}}
		>
			<img src={Yen} />
			<p>
				My passion for web development started with my Yahoo blog and
				then WordPress. I was a blogger who loved to write a bit of code
				to upgrade my blog at that time. It was just only some code to
				customize the blog, like adding image, adding header, changing
				side widgets or customizing the comments area.
			</p>{" "}
			<p>
				However, it felt exciting and fun when I did some nice layout or
				nice theme for my blog. I started the course from zero, I knew
				nothing about coding. And looking at all my projects, I'm
				grateful and proud of myself.
			</p>
			<p>
				<blockquote>
					"I think I've learned more than I expected."
				</blockquote>{" "}
			</p>
			<p>
				The decision to start this full-stack bootcamp was not easy as
				it seems to everyone. Despite everyone telling me that there are
				plenty of people who start over their career by taking a
				bootcamp in 9 weeks or more, I was still worried, A LOT to be
				honest.{" "}
			</p>
			<p>
				I spent nights, weeks to read about how hard is the bootcamp,
				what is the condition to join a bootcamp and what is the best
				school to do it. I doubted myself if I have the ability to work
				as a developer in the future. It took me 2 months to think it
				over and decided to stop my business and contact Barcelona Code
				School for a registering.
			</p>
			<p>
				I had good and bad experience during the bootcamp. The bad one –
				I knew it is hard, but I didn't know exactly how hard it will
				be. Therefore, I was shocked about the volume of knowledge I
				needed to master in a short time. Also, it is an online course,
				sometimes I feel alone because I'm on it alone.{" "}
			</p>
			<p>
				The good one – I have had excellent teachers who are willing to
				guide me through my course. They are patient and kind. Moreover,
				I have fun when I code. It is the most important thing to me. I
				like it and I finally can engage myself to it.
			</p>
			<p>
				What challenged me the most was{" "}
				<a
					href="https://projects.barcelonacodeschool.com/vietnam-tours/"
					target="_blank"
				>
					the final web app project
				</a>
				. From all the small exercises in JavaScript part you need to go
				big. Authentication, Stripe payment, back-end server. But at the
				end, with all the support from the school, it was an exciting
				and fun challenge.{" "}
			</p>
			<p>
				To be honest I thought about giving up more than 2 times. When I
				was stuck at some point, I thought just giving up is much
				easier. But I guess seeing all the students projects in the
				showcase/gallery helped me a lot. People can finish it and why
				not me?{" "}
			</p>
			<p>
				I guess this thought was my main motivation to start looking for
				a bootcamp. Moreover, after trying different things like doing
				business, working as tour guide I thought I can give it a try to
				learn about coding. Life is short and I do not want to stay the
				same all the time.{" "}
			</p>
			<p>
				Right now, I am{" "}
				<a href="https://yenhoang_portfolio.surge.sh" target="_blank">
					looking for a job as full-stack developer
				</a>
				. Meanwhile, I keep learning Next.js to sharpen my front-end
				skills. I hope I can use all my new skills for a fun and
				exciting future job. I am looking forward to it.
			</p>
			<p>
				My advice to all the future students – you probably should put a
				lot of efforts into the pre-course, try to prepare yourself as
				much as you can because during the course you will need to keep
				moving forward. But anyway, I think you guys are great, if I can
				do it, you absolutely can do it too as long as you get George
				and other teachers by your side.{" "}
			</p>
		</div>
	</div>
)

export default YenStory
